/**
 *
 * @param DateVal
 * @param format
 * @returns {Date}
 */
const formatDate = (DateVal, format='Y-M-D') => {

  /*
   * FORMATA DATA
   */
  var today = new Date(DateVal);
  var hh = today.getHours();
  var mn = today.getMinutes();
  var ss = today.getSeconds();
  var dd = today.getDate();
  var mm = today.getMonth()+1;
  var yyyy = today.getFullYear();

  if(dd<10) {
    dd='0'+dd;
  }

  if(mm<10) {
    mm='0'+mm;
  }

  if(hh<10) {
    hh='0'+hh;
  }

  if(mn<10) {
    mn='0'+mn;
  }

  if(ss<10) {
    ss='0'+ss;
  }

  if ( format == 'D/M/Y' )
    today = dd+'/'+mm+'/'+yyyy;
  else if ( format == 'D/M/Y H:i:s' )
    today = dd+'/'+mm+'/'+yyyy+' '+hh+':'+mn+':'+ss;
  else if ( format == 'Y-M-D H:i:s' )
    today = yyyy+'-'+mm+'-'+dd+' '+hh+':'+mn+':'+ss;
  else
    today = yyyy+'-'+mm+'-'+dd;

  return today;
}

/**
 *
 * @param strDate
 * @returns {number}
 */
const dateToTimeStamp = (strDate) => {
  const dt = new Date(strDate);
  return dt.getTime();
}

/**
 *
 * @param bytes
 * @param decimals
 * @returns {string}
 */
function formatBytes(bytes, decimals = 1, text = true) {
  var marker = 1024; // Change to 1000 if required
  var decimal = decimals; // Change as required
  var kiloBytes = marker; // One Kilobyte is 1024 bytes
  var megaBytes = marker * marker; // One MB is 1024 KB
  var gigaBytes = marker * marker * marker; // One GB is 1024 MB
  var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB

  // return bytes if less than a KB
  if(bytes < kiloBytes) return text ? bytes + " Bytes" : bytes;
  // return KB if less than a MB
  else if(bytes < megaBytes) return text ? (bytes / kiloBytes).toFixed(decimal) + " KB" : (bytes / kiloBytes).toFixed(decimal);
  // return MB if less than a GB
  else if(bytes < gigaBytes) return text ? (bytes / megaBytes).toFixed(decimal) + " MB" : (bytes / megaBytes).toFixed(decimal);
  // return GB if less than a TB
  else return text ? (bytes / gigaBytes).toFixed(decimal) + " GB" : (bytes / gigaBytes).toFixed(decimal);
}

/**
 *
 * @param name
 * @param value
 * @param exdays
 */
function setCookie(name, value, exdays) {

  var expires;
  var date;

  date = new Date(); //  criando o COOKIE com a data atual
  date.setTime(date.getTime()+(exdays*24*60*60*1000));
  expires = date.toUTCString();

  var cookie = name + "=" + escape(value) +
    ((expires) ? "; expires=" + expires : "");
  // ((expires) ? "; duration=" + expires : "");

  document.cookie = cookie;
}

function getCookie(name) {
  var cookies = document.cookie;
  var prefix = name + "=";
  var begin = cookies.indexOf("; " + prefix);

  if (begin == -1) {
    begin = cookies.indexOf(prefix);
    if (begin != 0) {
      return null;
    }
  } else {
    begin += 2;
  }

  var end = cookies.indexOf(";", begin);
  if (end == -1) {
    end = cookies.length;
  }

  return unescape(cookies.substring(begin + prefix.length, end));
}

function deleteCookie(name) {
  if (getCookie(name)) {
    document.cookie = name + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";
  }
}

function limitUpload(file) {
  if (file) {
    const maxSizeInBytes = 1000000; // 1MB
    const allowedFormats = ["image/jpeg", "image/png", "image/gif"];

    if (file.size > maxSizeInBytes) {
      alert("A imagem selecionada é muito grande. Por favor, escolha uma imagem menor.");
      return false;
    }

    if (!allowedFormats.includes(file.type)) {
      alert("Formato de arquivo não suportado. Por favor, selecione uma imagem JPEG, PNG ou GIF.");
      return false;
    }

    return true;
  } else {
    alert("Selecione um arquivo valido.");
    return false;
  }
};

/**
 *
 */
export { formatDate, dateToTimeStamp, formatBytes, setCookie, getCookie, deleteCookie, limitUpload };