/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

//
import DeleteIcon from '@mui/icons-material/Delete';
import {setGlobalState} from "../../../services/StoreServices";

export default function data(destaques) {

  const [destaquesDados, setDestaquesDados] = React.useState([{id_noticia_destaque: 0, titulo: '', descricao: '', status: '', slug: ''}]);
  const dados = destaques;

  React.useEffect(() => {
    setDestaquesDados(dados);
  }, []);

  const handleDelete = (e, id) => {
    e.preventDefault();
    console.log('handleDelete');
    console.log(id);

    const confirma = window.confirm('Deseja realmente excluir este registro?');

    if ( confirma === true ) {
      setGlobalState('deleteDestaqueState', id);
    }
  }

  const limitStr = (str, limit) => {

    if ( str === null ) {
      return '';
    }

    if ( str.length > limit ) {
      const varTemp = str.substring(0, limit);
      return `${varTemp}...`;
    }

    return str;
  }

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
        <MDTypography display="block" variant="button" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{limitStr(description, 100)}</MDTypography>
    </MDBox>
  );

  const montaDados = (dado) => {
    const rows = [];

    /**
     * @TODO - Descobrir po quando chama o Job da esse erro no console (Warning: Failed prop type: The prop `children` is marked as required in `ForwardRef`, but its value is `null`.)
     */
    dado.forEach((item) => {
      rows.push({
        noticia: <Job title={item.titulo} description={item.descricao}/>,
        link: (
          <MDTypography component="a" target="_blank" href={`${process.env.REACT_APP_SITE_HOST}/destaque/${item.slug}`} variant="caption" color="info" fontWeight="medium">
            Visualizar
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={item.status} color={item.status === 'ativo' ? 'success' : 'dark'}
                     variant="gradient" size="sm"/>
          </MDBox>
        ),
        action: (
          <MDTypography component="a"
                        href={`/admin/noticia-destaque-edit/${item.id_noticia_destaque}`}
                        sx={{ "&:hover": { color: "#000" } }}
                        variant="caption"
                        color="text"
                        fontWeight="medium">
            Edit
          </MDTypography>
        ),
        delete: (
            <DeleteIcon color="secondary"
                        fontSize="medium"
                        sx={{ "&:hover": { color: "#000" } }}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {handleDelete(e, item.id_noticia_destaque)}}
            />
        )
      });
    });

    return rows;
  }

  React.useEffect(() => {
    montaDados(destaquesDados);
  }, [destaquesDados]);

  return {
    columns: [
      { Header: "noticia", accessor: "noticia", width: "45%", align: "left" },
      { Header: "link", accessor: "link", align: "center" },
      { Header: "status", accessor: "status", width: "10%", align: "center" },
      { Header: "action", accessor: "action", width: "10%", align: "center" },
      { Header: "", accessor: "delete", width: "10%", align: "center" },
    ],

    rows: montaDados(dados),

    destaques: destaquesDados
  };
}
