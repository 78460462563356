/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

//
import DeleteIcon from '@mui/icons-material/Delete';
import {setGlobalState} from "../../../services/StoreServices";

export default function data(anuncios) {

  const [anunciosDados, setAnunciosDados] = React.useState([{id_anuncio: 0, titulo: '', descricao: '', status: '', slug: ''}]);
  const dados = anuncios;

  React.useEffect(() => {
    setAnunciosDados(dados);
  }, []);

  const handleDelete = (e, id) => {
    e.preventDefault();
    console.log('handleDelete');
    console.log(id);

    const confirma = window.confirm('Deseja realmente excluir este registro?');

    if ( confirma === true ) {
      setGlobalState('deleteAnuncioState', id);
    }
  }

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="button" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const montaDados = (dado) => {
    const rows = [];

    /**
     * @TODO - Descobrir pq quando chama o Job da esse erro no console (Warning: Failed prop type: The prop `children` is marked as required in `ForwardRef`, but its value is `null`.)
     */
    dado.forEach((item) => {
      rows.push({
        id: item.id_anuncio,
        tipo_anuncio: <Job title={item.tipo_anuncio} description={item.texto}/>,
        pagina: (
          <MDTypography variant="caption" color="text" fontWeight="medium" style={{textTransform: 'capitalize'}}>
            {item.pagina}
          </MDTypography>
        ),
        ordem: item.ordem,
        imagem: (
          <MDBox ml={-1}>
            <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${item.imagem}`} alt={item.titulo} style={{width: '80px', height: 'auto'}}/>
          </MDBox>
        ),
        // link: (
        //   <MDTypography component="a" target="_self" href={`/admin/anuncio-infos/${item.id_anuncio}`} variant="caption" color="orange" fontWeight="medium">
        //     Informações
        //   </MDTypography>
        // ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={item.status} color={item.status === 'ativo' ? 'success' : 'dark'}
                     variant="gradient" size="sm"/>
          </MDBox>
        ),
        action: (
          <MDTypography component="a"
                        href={`/admin/anuncio-edit/${item.id_anuncio}`}
                        sx={{ "&:hover": { color: "#000" } }}
                        variant="caption"
                        color="text"
                        fontWeight="medium">
            Edit
          </MDTypography>
        ),
        delete: (
          <DeleteIcon color="secondary"
                      fontSize="medium"
                      sx={{ "&:hover": { color: "#000" } }}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {handleDelete(e, item.id_anuncio)}}
          />
        )
      });
    });

    return rows;
  }

  React.useEffect(() => {
    montaDados(anunciosDados);
  }, [anunciosDados]);

  return {
    columns: [
      { Header: "id", accessor: "id", align: "left" },
      { Header: "tipo anuncio", accessor: "tipo_anuncio", align: "left" },
      { Header: "pagina", accessor: "pagina", align: "left" },
      { Header: "ordem", accessor: "ordem", align: "left" },
      { Header: "imagem", accessor: "imagem", align: "left" },
      // { Header: "link", accessor: "link", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
      { Header: "", accessor: "delete", align: "center" },
    ],

    rows: montaDados(dados),

    anuncios: anunciosDados
  };
}
