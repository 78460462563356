/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { useParams } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import AnuncioProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard/anuncio";

// Overview page components
import Header from "layouts/anuncios/components/Header";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import {getAction} from "../../services/ApiServices";

function EditAnuncio() {

  const { id } = useParams();
  const [alertMsg, setAlertMsg] = React.useState([]);
  const [anuncioDados, setAnuncioDados] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  /**
   *
   * @param dados
   * @returns {*[]}
   */
  const handleError = async (error) => {
    console.log( 'handleError' );
    console.log( error.errorMsg );
    switch (error.typeError) {
      case 'editable':
        await setAlertMsg({msg: error.errorMsg, min: 'agora', tit: 'Aviso'})
        break;
      case 'notFound':
        await setAlertMsg({msg: 'Ocorreu um erro!', min: 'agora', tit: 'Aviso'})
        break;
      default:
        // console.log( 'defaultCase' );
        await setAlertMsg({msg: 'Ocorreu um erro!', min: 'agora', tit: 'Aviso'})
        break;
    }

    setTimeout(() => {
      setAlertMsg({msg: null, min: null, tit: null})
    }, 30000);
  }

  const getAnuncio = async () => {

    const dados = await getAction(`/painel/anuncios/${id}`);

    if ( dados.error === false ) {
      // console.log('response.data');
      // console.log(dados.data);

      setAnuncioDados(dados.data);
      setIsLoading(false);
    }

    if ( dados.error === true ) {
      handleError(dados);
    }

  }

  React.useEffect(() => {
    getAnuncio();
  }, []);

  return (
    <DashboardLayout>
      <MDBox>
        <MDTypography variant="h3" fontWeight="medium" textTransform="capitalize">
          Editar Anuncio
        </MDTypography>
      </MDBox>
      <MDBox mb={2} />
      <Header>
        { /* * }
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox>
        { /* */ }
        <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={12} xl={12}>
              {(isLoading === false || id === "new") &&
                <AnuncioProjectCard
                  image={anuncioDados.imagem}
                  page={anuncioDados.pagina}
                  link={anuncioDados.link}
                  infos={{
                    id: id,
                    status: anuncioDados.status,
                    tipoAnuncio: anuncioDados.tipo_anuncio,
                    ordem: anuncioDados.ordem
                  }}
                />
              }
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default EditAnuncio;
