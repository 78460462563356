/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

//
import { Editor } from '@tinymce/tinymce-react';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";

//
import {createAction, updateAction} from "../../../../services/ApiServices";

function NoticiaDestaqueProjectCard({ image, imgCredito, title, description, infos, text }) {
  //
  const editorRef = React.useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  //
  const [alertMsg, setAlertMsg] = React.useState({msg: null, min: null, tit: null});
  const [status, setStatus] = React.useState(true);
  const [imagem, setImagem] = React.useState(image);
  const [imagemCredito, setImagemCredito] = React.useState(imgCredito);
  const [titulo, setTitulo] = React.useState(title);
  const [descricao, setDescricao] = React.useState(description);
  const [texto, setTexto] = React.useState(text);

  const fileInput = React.useRef(null)
  const [imagemUpload, setImagemUpload] = React.useState(null);

  React.useEffect(() => {

    if ( infos.status==="ativo" || infos.id==="new" ) {
      setStatus(true);
    } else {
      setStatus(false);
    }

    setImagem(image);
    setImagemCredito(imgCredito)
    setTitulo(title);
    setDescricao(description);
    setTexto(text);

  }, []);

  const handleChangeEditor = (content)  => {
    // console.log( 'handleChangeEditor' );
    // console.log( content );

    setTexto(content);
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    switch ( name ) {
      case 'titulo':
        setTitulo(value);
        break;

      case 'descricao':
        setDescricao(value);
        break;

      case 'imagem_credito':
        setImagemCredito(value);
        break;

      default:
        return false;
    }

    return true;
  }

  /**
   *
   * @param dados
   * @returns {*[]}
   */
  const handleError = async (error) => {
    console.log( 'handleError' );
    console.log( error.errorMsg );
    switch (error.typeError) {
      case 'editable':
        await setAlertMsg({msg: error.errorMsg, min: 'agora', tit: 'Aviso'})
        break;
      case 'notFound':
        await setAlertMsg({msg: 'Ocorreu um erro!', min: 'agora', tit: 'Aviso'})
        break;
      default:
        // console.log( 'defaultCase' );
        await setAlertMsg({msg: 'Ocorreu um erro!', min: 'agora', tit: 'Aviso'})
        break;
    }

    setTimeout(() => {
      setAlertMsg({msg: null, min: null, tit: null})
    }, 30000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Submit");

    const dadosToSend = {
      status: status?'ativo':'inativo',
      imagem: imagem,
      imagem_credito: imagemCredito,
      titulo: titulo,
      descricao: descricao,
      texto: texto,
    }
    // console.log( dadosToSend );

    let dados = null;

    if ( infos.id === "new" ) {
      dados = await createAction('/painel/noticias_destaques',dadosToSend);
    } else {
      dados = await updateAction(`/painel/noticias_destaques/${infos.id}`,dadosToSend)
    }

    if ( dados.error === undefined || dados.error === false ) {
      // console.log( 'ACTION dados' );
      // console.log( dados );

      sessionStorage.setItem('success','true');
      window.location = "/admin/noticias-destaques";
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  /**
   *
   * @param e
   */
  const handleImagemChange = (e) => {
    setImagemUpload(e.target.files[0]);
  };

  const handleUpload = () => {

    if ( imagemUpload === null ) {
      window.alert('Selecione uma imagem!');
      return false;
    }

    const formData = new FormData();
    formData.append('imagem', imagemUpload);

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/upload/upload.php?pasta=noticias_destaques`, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data); // Faça algo com a resposta do servidor
        // console.log( `${process.env.REACT_APP_API_ENDPOINT}/upload/${data.path}`  );

        setImagem( `${process.env.REACT_APP_API_ENDPOINT}/upload/${data.path}` )
        setImagemUpload(null);
      })
      .catch(error => {
        console.error(error);
      });

    return true;
  };

  return (
    <>
      {alertMsg.msg !== null &&
        <MDAlert color="error">{alertMsg.msg}</MDAlert>
      }
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "visible",
        }}
      >
        <MDBox position="relative" width="70%" shadow="xl" borderRadius="xl" ml="auto" mr="auto" mb={3}>
          <CardMedia
            src={imagem}
            component="img"
            title={titulo}
            sx={{
              maxWidth: "100%",
              margin: 0,
              boxShadow: ({ boxShadows: { md } }) => md,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />

        </MDBox>

        <MDBox position="relative" width="100%" ml="auto" mr="auto" mb={3} style={{textAlign:'right'}}>
          <input type="file" name='image' onChange={handleImagemChange} ref={fileInput} style={{display: 'none'}} />

          <MDButton
            component="button"
            href="#"
            size="small"
            color='secondary'
            onClick={() => fileInput.current.click()}
          >
            Escolher imagem
          </MDButton>
          {imagemUpload !== null &&
            <>
              <MDTypography variant="caption" component="div" color="text.secondary" style={{display:'inline-block',margin:'0 10px'}}>
                {imagemUpload.name}
              </MDTypography>
              &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;
              <MDButton
                variant={imagemUpload === null ? 'outlined' : 'contained'}
                component="button"
                href="#"
                size="small"
                color='secondary'
                onClick={handleUpload}
              >
                Enviar imagem
              </MDButton>
            </>
          }
        </MDBox>

        <MDBox mt={1} mx={0.5}>
          <MDBox mb={3}>
            <MDInput fullWidth type="text" label="Crédito Foto" name="imagem_credito" value={imagemCredito} onChange={handleChange}
                     inputProps={{
                       maxLength: 255
                     }}
            />
          </MDBox>
          <MDBox mb={3}>
            <MDInput fullWidth type="text" label="Título" name="titulo" value={titulo} onChange={handleChange}
                     inputProps={{
                       maxLength: 255
                      }}
            />
          </MDBox>
          <MDBox mb={3} lineHeight={0}>
            <MDInput fullWidth type="text" label="Descrição" name="descricao" value={descricao} onChange={handleChange}
                     inputProps={{
                       maxLength: 255
                     }}
            />
          </MDBox>

          <MDBox mb={3} lineHeight={0}>
            <>
              <Editor
                onEditorChange={handleChangeEditor}
                apiKey='do400h19p36064ihw1gnoa2nkrdm82mrqobfpiji7nb8jvk9'
                onInit={(evt, editor) => {
                  editorRef.current = editor
                }}
                value={texto}
                init={{
                  height: 400,
                  menubar: false,
                  branding: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | blocks fontfamily | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | code | image | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
              <MDButton onClick={log} style={{display: 'none'}}>Log editor content</MDButton>
            </>
          </MDBox>

          <MDBox display="flex" alignItems="center" mb={3}>
            <MDBox mt={0.5}>
              <Switch checked={status} onChange={() => setStatus(!status)} />
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography variant="button" fontWeight="regular" color="text">
                Status ({status ? "Ativo" : "Inativo"})
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDButton
              component='button'
              href="#"
              size="small"
              color='success'
              onClick={handleSubmit}
            >
              Salvar
            </MDButton>

            <MDButton
              component='button'
              href="#"
              size="small"
              color='info'
            >
              Cancelar
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

// Typechecking props for the DefaultProjectCard
NoticiaDestaqueProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  imgCredito: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  infos: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.string.isRequired,
};

export default NoticiaDestaqueProjectCard;
