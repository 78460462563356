/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";


// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import noticiasTableData from "./data/noticiasTableData";

//
import {delAction, getAllAction} from "../../services/ApiServices";
import {setGlobalState, useGlobalState} from "../../services/StoreServices";

function Noticias() {

  const [alertMsg, setAlertMsg] = React.useState({msg: null, min: null, tit: null});
  const [successMsg, setSuccessMsg] = React.useState({msg: null, min: null, tit: null});
  const [noticiasDados, setNoticiasDados] = React.useState([]);

  const deleteNoticiaState = useGlobalState('deleteNoticiaState');

  const { columns, rows } = noticiasTableData(noticiasDados);

  React.useEffect(() => {
    const sessionSuccess = sessionStorage.getItem('success');

    if ( sessionSuccess === "true" ) {
      sessionStorage.setItem('success','false');
      setSuccessMsg({msg: 'Salvo com sucesso!', min: 'agora', tit: 'Aviso'})

      setTimeout(() => {
        setSuccessMsg({msg: null, min: null, tit: null});
      },20000);
    }
  }, []);

  /**
   *
   * @param dados
   * @returns {*[]}
   */
  const handleError = async (error) => {
    console.log( 'handleError' );
    console.log( error.errorMsg );
    switch (error.typeError) {
      case 'editable':
        await setAlertMsg({msg: error.errorMsg, min: 'agora', tit: 'Aviso'})
        break;
      case 'notFound':
        await setAlertMsg({msg: 'Ocorreu um erro!', min: 'agora', tit: 'Aviso'})
        break;
      default:
        // console.log( 'defaultCase' );
        await setAlertMsg({msg: 'Ocorreu um erro!', min: 'agora', tit: 'Aviso'})
        break;
    }

    setTimeout(() => {
      setAlertMsg({msg: null, min: null, tit: null})
    }, 30000);
  }

  const getNoticias = async () => {

    const dados = await getAllAction('painel/noticias');

    if ( dados.error === false ) {
      // console.log('response.data');
      // console.log(dados.data._embedded.istv_noticias);

      setNoticiasDados(dados.data._embedded.istv_noticias);
    }

    if ( dados.error === true ) {
      handleError(dados);
    }

  }
  
  const deleteNoticia = async (idNoticia) => {

    setGlobalState('deleteNoticiaState', null);

    if ( idNoticia > 0 ) {
      const dados = await delAction('painel/noticias/', idNoticia)

      if ( dados.error === false ) {
        // console.log('response.data');
        // console.log(dados.data);

        const secondRemoved = noticiasDados.filter((item) => item.id_noticia !== idNoticia);
        setNoticiasDados(secondRemoved);

        setSuccessMsg({msg: 'Deletado com sucesso!', min: 'agora', tit: 'Aviso'})
        setTimeout(() => {
          setSuccessMsg({msg: null, min: null, tit: null})
        }, 20000);

        return true;
      }

      if ( dados.error === true ) {
        handleError(dados);

        return false;
      }
    }

    return false;

  }

  /**
   * 
   */
  React.useEffect(() => {
    getNoticias();
  }, []);

  /**
   *
   */
  React.useEffect(() => {

    if ( deleteNoticiaState[0] !== null ) {
      console.log('deleteNoticiaState');
      console.log(deleteNoticiaState);
      // setGlobalState('deleteNoticiaState', null);

      deleteNoticia(deleteNoticiaState[0]);
    }

  }, [deleteNoticiaState]);
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {successMsg.msg !== null &&
        <MDAlert color="success">{successMsg.msg}</MDAlert>
      }
      {alertMsg.msg !== null &&
        <MDAlert color="error">{alertMsg.msg}</MDAlert>
      }
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="secondary"
                style={{ display: "block ruby" }}
              >
                <MDTypography variant="h6" color="white">
                  Notícias
                </MDTypography>
                <MDButton color="info"
                          onClick={() => {
                            window.location.href = '/admin/noticia-edit/new';
                          }}
                          style={{float:'right'}} >
                  Criar Novo
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>

        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Noticias;
