/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/5594016.jpg";

//
import { loginAction } from '../../../services/ApiServices';
import { TOKEN_KEY } from "../../../services/Auth";
import { deleteCookie } from "../../../services/Functions";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  //
  const [loginData, setLoginData] = useState({lg:null, pw:null});
  const [alertMsg, setAlertMsg] = useState({msg: null});

  useEffect(() => {
    console.log( 'clean session' );
    // sessionStorage.removeItem('loginClient');
    // sessionStorage.removeItem('loginAdmin');
    // sessionStorage.removeItem('sitesUser');
    // deleteCookie('sitesUserCk');
    // deleteCookie('siteSelectedCk');

    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('getHash');
    localStorage.removeItem('dadosUser');
  }, []);

  /**
   * @param event
   * @returns {Promise<void>}
   */
  const handleError = async (error) => {
    switch (error.typeError) {
      case 'editable':
        await setAlertMsg({msg: error.errorMsg})
        break;
      case 'notFound':
        await setAlertMsg({msg: 'Ocorreu um erro!'})
        break;
      case '401':
        if ( error.errorDetail.detail === 'Invalid username and password combination' ) {
          await setAlertMsg({msg: 'Usuário e/ou senha inválido(s)!'})
        } else {
          await setAlertMsg({msg: 'Acesso bloqueado!'})
        }
        break;
      default:
        await setAlertMsg({msg: 'Ocorreu um erro!'})
        break;
    }

    setTimeout(() => {
      setAlertMsg({msg: null})
    }, 8000);
  }

  const handleSubmit = async event => {
    event.preventDefault();

    let mensagemAlert = null;

    if ( (loginData.lg === '' || loginData.lg === null || loginData.lg === undefined) )
      mensagemAlert = 'Favor preencher o login!';

    if ( (loginData.pw === '' || loginData.pw === null || loginData.pw === undefined) )
    {
      if ( alertMsg != null ) {
        mensagemAlert = "\n Favor preencher a senha!";
      } else
      {
        mensagemAlert = 'Favor preencher a senha!';
      }
    }

    if ( mensagemAlert != null ) {
      await setAlertMsg({...alertMsg, msg: mensagemAlert});

      setTimeout(() => {
        setAlertMsg({msg: null})
      }, 6000);

      return false;
    }

    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem('getHash',null)
    let scope     = null;
    let clientId = null;

    //
    localStorage.setItem('getHash','cGFpbmVsX3VzZXI6aXN0djIwMjNwYW5lbA==')
    scope     = "administrador painel";
    // scope     = null;
    clientId = "painel_user";

    const loginArray = {
      username: loginData.lg,
      password: loginData.pw,
      grant_type: "password",
      client_id: clientId,
      scope: scope
    };

    const dados = await loginAction('oauth', loginArray);

    if ( dados.error === false ) {
      console.log( 'error false' );

      const dadosUser = JSON.stringify({login: loginData.lg});
      localStorage.setItem('dadosUser', dadosUser);

      window.location = "/admin/dashboard";
    }

    if ( dados.error === true ) {
      console.log( 'error true' );
      console.log( dados );
      handleError(dados);
    }

    return true;
  }

  /**
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleChange = async event => {

    await setLoginData({
      ...loginData,
      [event.target.name]: event.target.value
    });
  };

  return (
    <BasicLayout image={bgImage}>

      {alertMsg.msg != null && (
          <div id="alertLogin" className="alertLogin">
            <MDBox marginBottom="50px" marginLeft="auto" marginRight="auto">
              <MDAlert color="error" dismissible>
                <MDTypography fontSize="medium" color="white">{alertMsg.msg}</MDTypography>
              </MDAlert>
            </MDBox>
          </div>
      )}

      <Card>
        <MDBox
          variant="gradient"
          bgColor="secondary"
          borderRadius="lg"
          coloredShadow="secondary"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
            Acessar
          </MDTypography>
          { /* * }
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
          { /* */ }
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Login" fullWidth
                       name="lg"
                       onKeyPress={(e) => {
                         if (e.key === "Enter") {
                           handleSubmit(e);
                         }
                       }}
                       onChange={handleChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Senha" fullWidth
                       name="pw"
                       onKeyPress={(e) => {
                         if (e.key === "Enter") {
                           handleSubmit(e);
                         }
                       }}
                       onChange={handleChange}
              />
            </MDBox>
            { /* * }
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            { /* */ }
            <MDBox mt={4} mb={1}>
              <MDButton onClick={handleSubmit} variant="gradient" color="secondary" fullWidth>
                Entrar
              </MDButton>
            </MDBox>
            { /* * }
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
            { /* */ }
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
