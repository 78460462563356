import { createGlobalState } from 'react-hooks-global-state';
// import {getCookie} from "./Functions";

const { setGlobalState, useGlobalState } = createGlobalState({
  defaultState: 'My global store',
  alertMsgState: {msg: null, min: null, tit: null},
  isLoadingState: true,
  isLoggedInState: false,
  // gatewaySiteSelectedState: (getCookie('gatewaySiteSelectedCk') !== null) ? getCookie('gatewaySiteSelectedCk') : null,
  deleteDestaqueState: null,
  deleteNoticiaState: null,
  deleteAnuncioState: null
});

export { setGlobalState, useGlobalState };