/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, {useEffect} from "react";

//
import { Editor } from '@tinymce/tinymce-react';

// prop-types is a library for typechecking of props
import PropTypes, { checkPropTypes } from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";

//
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { limitUpload } from "services/Functions";
import {createAction, updateAction} from "../../../../services/ApiServices";

function AnuncioProjectCard({ image, page, infos, link }) {
  //
  const editorRef = React.useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  //
  const [alertMsg, setAlertMsg] = React.useState({msg: null, min: null, tit: null});
  const [status, setStatus] = React.useState(true);
  const [imagem, setImagem] = React.useState(image);

  const fileInput = React.useRef(null)
  const [imagemUpload, setImagemUpload] = React.useState(null);
  const [idTipo, setIdTipo] = React.useState([]);
  const [tipos, setTipos] = React.useState(['topo', 'noticia', 'horizontal', 'ct_pequeno', 'ct_grande']);

  const [idPagina, setIdPagina] = React.useState([]);
  const [paginas, setPaginas] = React.useState(['home','todas','contato']);
  const [loading, setLoading] = React.useState(false);
  const [ordem, setOrdem] = React.useState('');


  const [formState, setFormState] = React.useState({
    link: link
  });

  React.useEffect(() => {

    if ( infos.status==="ativo" || infos.id==="new" ) {
      setStatus(true);
    } else {
      setStatus(false);
    }

    setImagem(image);
    setIdTipo(infos.tipoAnuncio);
    setIdPagina(page);
    setOrdem(infos.ordem || '');

  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setFormState(prev => ({
      ...prev,
      [name]: value
    }));

    return true;
  };

  /**
   *
   * @param dados
   * @returns {*[]}
   */
  const handleError = async (error) => {
    console.log( 'handleError' );
    console.log( error.errorMsg );
    switch (error.typeError) {
      case 'editable':
        await setAlertMsg({msg: error.errorMsg, min: 'agora', tit: 'Aviso'})
        break;
      case 'notFound':
        await setAlertMsg({msg: 'Ocorreu um erro!', min: 'agora', tit: 'Aviso'})
        break;
      default:
        // console.log( 'defaultCase' );
        await setAlertMsg({msg: 'Ocorreu um erro!', min: 'agora', tit: 'Aviso'})
        break;
    }

    setTimeout(() => {
      setAlertMsg({msg: null, min: null, tit: null})
    }, 30000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Submit");

    const dadosToSend = {
      status: status?'ativo':'inativo',
      imagem: imagem,
      link: formState.link,
      tipo_anuncio: idTipo,
      pagina: idPagina,
      adsense: 'nao',
      ordem: ordem || null
    }
    // console.log( 'dadosToSend' );
    // console.log( dadosToSend );
    // return false;

    /*
     *
     */
    let dados = null;

    if ( infos.id === "new" ) {
      dados = await createAction('/painel/anuncios',dadosToSend);
    } else {
      dados = await updateAction(`/painel/anuncios/${infos.id}`,dadosToSend)
    }

    if ( dados.error === undefined || dados.error === false ) {
      // console.log( 'ACTION dados' );
      // console.log( dados );

      sessionStorage.setItem('success','true');
      window.location = "/admin/anuncios";
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
    /*
     *
     */
  }

  /**
   *
   * @param e
   */
  const handleImagemChange = (e) => {
    const file = e.target.files[0];

    const isGif = file.name.toLowerCase().endsWith('.gif');

    if (isGif) {
      setImagemUpload(file);
    } else {

      const checkUploadSize = limitUpload(file);
      if (checkUploadSize) {
        setImagemUpload(file);

      }
    }

  };

  const handleUpload = () => {
    setLoading(true);

    if ( imagemUpload === null ) {
      window.alert('Selecione uma imagem!');
      setLoading(false);
      return false;
    }

    const formData = new FormData();
    formData.append('imagem', imagemUpload);

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/upload/upload.php?pasta=anuncios`, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data); // Faça algo com a resposta do servidor
        // console.log( `${process.env.REACT_APP_API_ENDPOINT}/upload/${data.path}`  );

        setImagem( `${data.path}` )
        setImagemUpload(null);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });

    return true;
  };

  useEffect(() => {
    if ( imagemUpload !== null ) {
      handleUpload();
    }
  }, [imagemUpload]);

  return (
    <>
      {alertMsg.msg !== null &&
        <MDAlert color="error">{alertMsg.msg}</MDAlert>
      }
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "visible",
        }}
      >

        <MDTypography variant="button" fontWeight="regular" color="text">
          HOME (noticia) - Lateral/Vertical = 300x722 px
          <br/>
          HOME (topo) - Retângulo Horizontal Topo = 1280x322 px
          <br/>
          HOME (horizontal) - Retângulo Horizontal Rodapé = 1280x158 px
          <br/><br/>
          CONTATO - Lateral/Vertical (ct_grande) = 300x722 px
          <br/>
          CONTATO Quadrado (ct_pequeno) = 358x299 px
          <br/><br/><br/>
        </MDTypography>

        <MDBox position="relative" width="70%" shadow="xl" borderRadius="xl" ml="auto" mr="auto" mb={3}
               style={{maxWidth: '250px'}} >
          <CardMedia
            src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${imagem}`}
            component="img"
            sx={{
              maxWidth: "250px",
              margin: 0,
              boxShadow: ({ boxShadows: { md } }) => md,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />

        </MDBox>


        <MDBox position="relative" width="100%" ml="auto" mr="auto" mb={3} style={{textAlign:'right'}}>
          <input type="file" name='image' onChange={handleImagemChange} ref={fileInput} style={{display: 'none'}} />

          <MDButton
            component="button"
            size="small"
            color='secondary'
            onClick={() => fileInput.current.click()}
          >
            Escolher imagem
          </MDButton>
          {imagemUpload !== null &&
            <>
              <MDTypography variant="caption" component="div" color="text.secondary" style={{display:'inline-block',margin:'0 10px'}}>
                {imagemUpload.name}
              </MDTypography>
              &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;
              <MDButton
                variant={imagemUpload === null ? 'outlined' : 'contained'}
                component="button"
                href="#"
                size="small"
                color='secondary'

                onClick={handleUpload}
              >
                Enviar imagem
              </MDButton>
            </>
          }
        </MDBox>

        <MDBox mb={3}>
          <MDInput fullWidth type="url" label="Link" name="link" value={formState.link} onChange={handleChange}
                   inputProps={{
                     maxLength: 255
                   }}
          />
        </MDBox>

        <MDBox display="flex" alignItems="center" mb={3}>
          <MDBox mt={0.5}>
            <FormControl>
              <InputLabel id="status-select-label">Selecione um tipo</InputLabel>
              <Select
                style={{minWidth: '200px', width: 'auto', height: '44px'}}
                labelId="status-select-label"
                label="Selecione um tipo"
                value={idTipo}
                onChange={(e) => setIdTipo(e.target.value)}
              >
                {tipos.map((tipo) => (
                  <MenuItem label={tipo} key={tipo} value={tipo}>{tipo}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
        </MDBox>

        <MDBox display="flex" alignItems="center" mb={3}>
          <MDBox mt={0.5}>
            <TextField
              label="Ordem"
              type="number"
              inputProps={{ min: "1", step: "1" }}
              value={ordem}
              onChange={(e) => setOrdem(e.target.value)}
              fullWidth
            />
          </MDBox>
        </MDBox>


        <MDBox display="flex" alignItems="center" mb={3}>
          <MDBox mt={0.5}>
            <FormControl>
              <InputLabel id="pagina-select-label">Selecione uma pagina</InputLabel>
              <Select
                style={{minWidth: '200px', width: 'auto', height: '44px'}}
                labelId="pagina-select-label"
                label="Selecione uma pagina"
                value={idPagina}
                onChange={(e) => setIdPagina(e.target.value)}
              >
                {paginas.map((pagina) => (
                  <MenuItem label={pagina} key={pagina} value={pagina}>{pagina}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
        </MDBox>




        <MDBox display="flex" alignItems="center" mb={3}>
          <MDBox mt={0.5}>
            <Switch checked={status} onChange={() => setStatus(!status)} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Status ({status ? "Ativo" : "Inativo"})
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDButton
            component='button'
            href="#"
            size="small"
            color='success'
            onClick={handleSubmit}
          >
            Salvar
          </MDButton>

          <MDButton
            component='button'
            href="#"
            size="small"
            color='info'
          >
            Cancelar
          </MDButton>
        </MDBox>

      </Card>
    </>
  );
}

// Typechecking props for the DefaultProjectCard
AnuncioProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  infos: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string,
    tipoAnuncio: PropTypes.string,
    ordem: PropTypes.number,
  }).isRequired
};

export default AnuncioProjectCard;
